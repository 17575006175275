import React, { FC } from 'react';
import { ProductStepper } from 'app/components/product/ProductStepper';
import { getProductTranslationKey } from 'app/functions/product';
import * as styles from './ProductList.scss';
import { ProductsId, productsConfig } from '@webc/meeseeks-ui-sdk';
import { ProductDataType } from 'app/models/types/store/product';
import { isNecCardSelector } from 'app/store/selectors';
import { useSelector } from 'react-redux';

export type ProductListProps = {
  products: { [key in ProductsId]?: ProductDataType };
  onSetProductQuantity: (x: ProductsId, y: number) => void;
  onProductMessagesSeen: (key: ProductsId, value: boolean) => void;
  productMessagesSeen: Record<string, boolean>;
  maxTotalPassengerCount?: number;
  passengerCount: number;
  tabTrapAction?: (e: React.KeyboardEvent) => void;
  usVeteransPromoted?: boolean;
};

export const ProductList: FC<ProductListProps> = ({
  products,
  onSetProductQuantity,
  maxTotalPassengerCount,
  passengerCount,
  productMessagesSeen,
  onProductMessagesSeen,
  tabTrapAction,
  usVeteransPromoted,
}) => {
  const isNecCard = useSelector(isNecCardSelector);

  const getMaxAmount = (product: ProductDataType) => {
    if (productsConfig.productTypes[product.id]?.shouldMatchDefaultProductAmount) {
      return products['adult']?.selectedAmount;
    }
    return product.maxAmount;
  };

  return (
    <div className={styles.productPicker}>
      {Object.keys(products).map((productType, i) => {
        let description = getProductTranslationKey(productType, 'description');

        if (productType === 'adult') {
          if (isNecCard) {
            description = getProductTranslationKey('sct_nec_adult', 'description');
          } else {
            description = '';
          }
        }

        if (
          (productType === 'us_veteran' &&
            usVeteransPromoted &&
            products[productType]?.selectedAmount === 0) ||
          (productType === 'us_veteran' && !usVeteransPromoted)
        ) {
          description = '';
        }

        return (
          <ProductStepper
            key={productType}
            productType={productType as ProductsId}
            title={getProductTranslationKey(productType, 'label')}
            description={description}
            onChange={(newQuantity) => {
              onSetProductQuantity(productType as ProductsId, newQuantity);
            }}
            value={products[productType].selectedAmount}
            max={getMaxAmount(products[productType])}
            maxTotalPassengerCount={maxTotalPassengerCount}
            passengerCount={passengerCount}
            onProductMessagesSeen={onProductMessagesSeen}
            productMessagesSeen={productMessagesSeen}
            tabTrapAction={i + 1 === Object.keys(products).length ? tabTrapAction : undefined}
          />
        );
      })}
    </div>
  );
};

export default ProductList;
