import React, { FC } from 'react';
import { getTestingAttributes } from 'app/functions';
import { elements } from 'app/tests/elements';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { isSearchInvalid } from 'app/functions/general';
import {
  arrivalCitySelector,
  departureCitySelector,
  departureDateTimestampSelector,
  localeSelector,
  passengerCountSelector,
  wheelchairSelectedSelector,
} from 'app/store/selectors';
import * as styles from './SearchButton.scss';
import { useTranslations } from 'app/hooks/useTranslations';
import { CityDetailsResult } from 'app/models/types/store/location';
import { Button } from '@flixbus/honeycomb-react';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import { getIsGlobalTripSearch } from '@webc/meeseeks-ui-sdk';
import { updateFocusedPart } from 'app/store/reducers/ui';
import { requestSearch } from 'app/store/thunk/general/requestSearchThunk';
import { useAppDispatch } from 'app/hooks/appHooks';

export const SearchButton: FC = () => {
  const locale = useSelector(localeSelector);
  const departureCity = useSelector(departureCitySelector);
  const arrivalCity = useSelector(arrivalCitySelector);
  const departureDate = useSelector(departureDateTimestampSelector);
  const passengerCount = useSelector(passengerCountSelector);
  const wheelchairsCount = useSelector(wheelchairSelectedSelector);
  const isGlobalTripSearch = getIsGlobalTripSearch({
    locale,
    departureCountryCode: departureCity.countryCode,
    arrivalCountryCode: arrivalCity.countryCode,
  });

  const dispatch = useAppDispatch();

  const { getSearchString, getSearchButtonAriaLabel } = useTranslations({
    getSearchString: 'Booking find',
    getSearchButtonAriaLabel: a11yLabelsKeys.searchButton,
  });

  const handleClick = () => {
    if (!departureCity.uuid || !arrivalCity.uuid) {
      return;
    }

    dispatch(requestSearch({ isGlobalTripSearch }));
  };

  const handleFocus = () => {
    dispatch(updateFocusedPart('button'));
  };

  return (
    <div {...getTestingAttributes(elements.SEARCH_BUTTON)} className={styles.searchButtonWrapper}>
      <Button
        appearance="primary"
        aria-label={getSearchButtonAriaLabel()}
        extraClasses={cn(styles.searchButton)}
        onClick={handleClick}
        onFocus={handleFocus}
        disabled={isSearchInvalid({
          passengerCount,
          departureDate,
          wheelchairsCount,
          departureCity: departureCity as CityDetailsResult,
          arrivalCity: arrivalCity as CityDetailsResult,
        })}
      >
        {getSearchString()}
      </Button>
    </div>
  );
};
