import { combineReducers } from '@reduxjs/toolkit';
import { generalSlice } from './general';
import { uiSlice } from './ui';
import date from './date';
import location from './location';
import product from './product';
import { translationSlice } from './translation';

export default combineReducers({
  [generalSlice.reducerPath]: generalSlice.reducer,
  [uiSlice.reducerPath]: uiSlice.reducer,
  date,
  location,
  product,
  [translationSlice.reducerPath]: translationSlice.reducer,
});
