import { StoreTranslationsDateField } from 'app/models/types/store/translations';
import { DEFAULT_CODE } from './translations';

const fetchDates = (locale: string) =>
  fetch(`${process.env.SEARCH_PUBLIC_URL}dates/${locale}.json`).then((res) => res.json());

export const fetchDatesTranslations = async (
  locale: string,
): Promise<StoreTranslationsDateField> => {
  let dates;

  try {
    dates = await fetchDates(locale);
  } catch {
    dates = await fetchDates(DEFAULT_CODE);
  }

  return dates;
};
