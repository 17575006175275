import { call, put, select, takeEvery } from 'redux-saga/effects';
import { clearAutocomplete, SetLocationAction, SET_LOCATION } from 'app/store/actions';
import {
  cmsApiOnArrivalChangeSelector,
  cmsApiOnDepartureChangeSelector,
} from 'app/store/selectors';

export function* onSetLocationWorker(action: SetLocationAction) {
  // construct and send payload to cmsApi
  const payload = {
    cityName: action.location.name,
    legacyId: action.location.legacyId,
    uuid: action.location.uuid,
  };
  const onDepartureChanged: ReturnType<typeof cmsApiOnDepartureChangeSelector> = yield select(
    cmsApiOnDepartureChangeSelector,
  );
  const onArrivalChanged: ReturnType<typeof cmsApiOnArrivalChangeSelector> = yield select(
    cmsApiOnArrivalChangeSelector,
  );

  const method = action.direction === 'from' ? onDepartureChanged : onArrivalChanged;
  yield call(method, payload);

  // clear autocomplete results for this direction
  yield put(clearAutocomplete(action.direction));
}

export function* onSetLocation() {
  yield takeEvery<SetLocationAction>(SET_LOCATION, onSetLocationWorker);
}
