import React, { useRef } from 'react';
import {
  Panel,
  PanelHeader,
  PanelContent,
  PanelFooter,
  PanelFooterColumn,
} from '@flixbus/honeycomb-react';
import { useTranslations } from 'app/hooks/useTranslations';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import { ProductPanelType } from 'app/models/types/store/ui';
import * as styles from './ProductMobilePanel.scss';
import { useSelector } from 'react-redux';
import { openedOverlaySelector } from 'app/store/selectors';
import { ProductPopupContent } from 'app/components/product/ProductPopupContent/ProductPopupContent';
import { ConfirmButton } from 'app/components/ui/ConfirmButton/ConfirmButton';
import { toggleOverlay } from 'app/store/reducers/ui';
import { useAppDispatch } from 'app/hooks/appHooks';

export const ProductMobilePanel = () => {
  const { getCloseLabel, getTitle } = useTranslations({
    getCloseLabel: a11yLabelsKeys.close,
    getTitle: 'search_bundle.search_widget.passengers',
  });
  const overlayData = useSelector(openedOverlaySelector) || ({} as ProductPanelType);
  const { name, props } = overlayData as ProductPanelType;
  const { handleClose } = props || {};
  const wrapperRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const onClose = () => {
    if (handleClose) {
      handleClose();
    }
    dispatch(toggleOverlay({ name: 'ProductPanel' }));
  };

  const isActive = name === 'ProductPanel' && !!props;
  if (!isActive) {
    return null;
  }

  return (
    <div ref={wrapperRef}>
      <Panel
        id="mobile-panel"
        extraClasses={styles.panelWrapper}
        active={isActive}
        position="bottom"
        fullSize
        hasOverlay
        onOverlayClick={onClose}
      >
        <PanelHeader
          closeButtonProps={{
            label: getCloseLabel(),
            onClick: onClose,
            id: 'close-button',
          }}
          extraClasses={styles.panelHeader}
        >
          {getTitle()}
        </PanelHeader>
        <PanelContent extraClasses={styles.panelContent}>
          <ProductPopupContent {...props} />
        </PanelContent>
        <PanelFooter extraClasses={styles.panelFooter}>
          <PanelFooterColumn>
            <ConfirmButton id="confirm-button" onClick={onClose} />
          </PanelFooterColumn>
        </PanelFooter>
      </Panel>
    </div>
  );
};
