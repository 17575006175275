import {
  clearLocation,
  setDepartureDate,
  setReturnDate,
  updateSearchLocation,
} from 'app/store/actions';
import { StoreProductField } from 'app/models/types/store/product';
import { StoreState } from 'app/models/types';
import { SearchMaskLocation } from 'app/models/types/store/location';
import { requestSearch } from 'app/store/thunk/general/requestSearchThunk';
import { AppStore } from 'app/hooks/appHooks';

export type CmsApi = {
  setDepartureCity: (params: { uuid: string; cityName: string }) => void;
  setArrivalCity: (params: { uuid: string; cityName: string }) => void;
  setDepartureDate: (date: number) => void;
  setReturnDate: (date: number) => void;
  onDepartureDateChanged: (params: { date: number; dateLocalized: string }) => void;
  onReturnDateChanged: (params: { date: number; dateLocalized: string }) => void;
  onProductChanged: (params: StoreProductField['types']) => void;
  onDepartureCityChanged: (params: { uuid: string; cityName: string; legacyId: number }) => void;
  onArrivalCityChanged: (params: { uuid: string; cityName: string; legacyId: number }) => void;
  clearDepartureLocation: () => void;
  clearArrivalLocation: () => void;
};

export const cmsApi = (store: AppStore): CmsApi => ({
  setDepartureCity: ({ uuid, cityName }: { uuid: string; cityName: string }) => {
    store.dispatch(
      updateSearchLocation({
        direction: 'from',
        location: { name: cityName, uuid: uuid } as SearchMaskLocation,
      }),
    );
  },
  setArrivalCity: ({ uuid, cityName }: { uuid: string; cityName: string }) => {
    store.dispatch(
      updateSearchLocation({
        direction: 'to',
        location: { name: cityName, uuid: uuid } as SearchMaskLocation,
      }),
    );
  },
  setDepartureDate: (date: number) => {
    store.dispatch(setDepartureDate(date));
    const { general } = store.getState() as StoreState;
    const isAutoUpdate = general.options.autoUpdate;
    if (isAutoUpdate) {
      store.dispatch(requestSearch({}));
    }
  },
  setReturnDate: (date: number) => {
    store.dispatch(setReturnDate(date));
    const { general } = store.getState() as StoreState;
    const isAutoUpdate = general.options.autoUpdate;
    if (isAutoUpdate) {
      store.dispatch(requestSearch({}));
    }
  },
  onDepartureDateChanged: (_params: { date: number; dateLocalized: string }) => {},
  onReturnDateChanged: (_params: { date: number; dateLocalized: string }) => {},
  onProductChanged: (_params: StoreProductField['types']) => {},
  onDepartureCityChanged: (_params: { uuid: string; cityName: string; legacyId: number }) => {},
  onArrivalCityChanged: (_params: { uuid: string; cityName: string; legacyId: number }) => {},
  clearDepartureLocation: () => store.dispatch(clearLocation('from')),
  clearArrivalLocation: () => store.dispatch(clearLocation('to')),
});
