import { takeEvery, select, call, put } from 'redux-saga/effects';
import { mapCityDetailResponse } from 'app/functions';
import { SearchMaskDirection, SearchMaskLocation } from 'app/models/types/store/location';
import {
  arrivalCitySelector,
  currentProductTypesExtendedSelector,
  currentProductTypesSelector,
  optionsSelector,
  showStationSuggestionSelector,
} from 'app/store/selectors';
import { fetchCityRelationDetails } from 'app/providers';
import {
  requestAutocomplete,
  resetAllProducts,
  setLocation,
  setRelationProducts,
  UPDATE_SEARCH_LOCATION,
  updateAutocompleteValue,
} from 'app/store/actions';
import { requestSearch } from '../thunk/general';

export function* onUpdateSearchLocationsWatcher({
  location,
  direction,
  isAutoUpdate,
  previousLocations,
  isInit,
}: {
  type: string;
  location: SearchMaskLocation;
  previousLocations?: { departureCity: SearchMaskLocation; arrivalCity: SearchMaskLocation };
  direction: SearchMaskDirection;
  isAutoUpdate?: boolean;
  isInit?: boolean;
}) {
  const showStationSuggestions: boolean = yield select(showStationSuggestionSelector);
  const { citiesDetailsEndpoint, locale }: ReturnType<typeof optionsSelector> =
    yield select(optionsSelector);
  const previouslySelectedLocation =
    direction === 'from'
      ? previousLocations?.departureCity || {}
      : previousLocations?.arrivalCity || {};

  // if we are updating "from" city ensure to flush autocomplete results for "to" city
  const arrivalCity: ReturnType<typeof arrivalCitySelector> = yield select(arrivalCitySelector);
  if (direction === 'from' && arrivalCity.name) {
    yield put(requestAutocomplete(arrivalCity.name, 'to', true));
  }

  let locationDetails;
  const cityId = location.uuid ? location.uuid : location.legacyId;

  //get city details
  const cityDetailsResponse: Awaited<ReturnType<typeof fetchCityRelationDetails>> = yield call<
    typeof fetchCityRelationDetails
  >(fetchCityRelationDetails, citiesDetailsEndpoint, cityId, locale);
  if ('code' in cityDetailsResponse) {
    console.error(`Can't retrieve city for location`, location);
    yield put(setLocation(direction, previouslySelectedLocation as SearchMaskLocation));

    if (!isInit) {
      return;
    }
    locationDetails = location;
  } else {
    const [result] = cityDetailsResponse.map((c) => mapCityDetailResponse(c));
    locationDetails = {
      ...result,
      stationId: location.stationId,
      stationName: location.stationName,
    };
  }

  yield put(updateAutocompleteValue(direction, locationDetails));

  //set products for relation
  const currentProductTypes: ReturnType<typeof currentProductTypesSelector> = yield select(
    currentProductTypesSelector,
  );
  const currentProductTypesExtended: ReturnType<typeof currentProductTypesExtendedSelector> =
    yield select(currentProductTypesExtendedSelector);
  yield put(resetAllProducts());
  yield put(setRelationProducts({ ...currentProductTypes, ...currentProductTypesExtended }));

  //request search for autoupdate
  if (isAutoUpdate) {
    const isNewCity =
      (direction === 'from' && previousLocations?.departureCity.uuid !== locationDetails.uuid) ||
      (direction === 'to' && previousLocations?.arrivalCity.uuid !== locationDetails.uuid);

    const isNewStation =
      (direction === 'from' &&
        previousLocations?.departureCity.stationId !== locationDetails.stationId) ||
      (direction === 'to' &&
        previousLocations?.arrivalCity.stationId !== locationDetails.stationId);

    const isNew = isNewCity || isNewStation;

    if (isNew) {
      yield put(requestSearch({}));
    }
  }
  const newLocation = {
    ...locationDetails,
    ...(showStationSuggestions
      ? {
          stationId: location.stationId,
          stationName: location.stationName,
        }
      : null),
  };
  yield put(setLocation(direction, newLocation));
}

export function* onUpdateSearchLocations() {
  yield takeEvery(UPDATE_SEARCH_LOCATION, onUpdateSearchLocationsWatcher);
}
