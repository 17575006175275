import { emitFieldChangedEvent } from 'app/events';
import { useAppDispatch } from 'app/hooks/appHooks';
import { switchLocations } from 'app/store/actions';
import { autoUpdateSelector } from 'app/store/selectors';
import { requestSearch } from 'app/store/thunk/general/requestSearchThunk';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

export const useLocationSwitcher = () => {
  const dispatch = useAppDispatch();

  const isAutoUpdate = useSelector(autoUpdateSelector);

  const [rotate, setRotate] = useState(false);

  const timeout = useRef<ReturnType<typeof window.setTimeout> | null>(null);

  useEffect(() => {
    if (rotate) {
      timeout.current = setTimeout(() => {
        setRotate(false);
      }, 300);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [rotate]);

  const handleSwitchLocations = () => {
    emitFieldChangedEvent('citySwitcher', undefined);

    setRotate(true);

    dispatch(switchLocations());

    if (isAutoUpdate) {
      dispatch(requestSearch({}));
    }
  };

  return {
    handleSwitchLocations,
    rotate,
  };
};
