import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {
  setReturnDateWatcher,
  onUpdateOptions,
  onRemoveBikesFromProduct,
  onUpdateLocations,
  onUpdateDates,
  onRetrieveRecentCities,
  onLoadPopularCities,
  onLoadArrivalPopularCities,
  onStoreRecentCity,
  onRemoveRecentCity,
  onSetLocation,
  onSwitchLocations,
  onRequestAutocomplete,
  onSetDepartureDate,
  onSetArrivalDate,
  onHideReturnDate,
  onSetProductQuantity,
  onUpdateSearchLocations,
} from 'app/store/sagas';
import { features as defaultFeatures } from 'app/config/features';
import { featureToggler } from 'app/functions/abTests/featureToggler';
import { getOptions, variants } from 'app/options';
import rootReducer from './reducers';
import { isDevelopment } from 'app/functions';
import { isIsolated } from 'app/functions/environment/isIsolated';
import { StoreGeneralField } from 'app/models/types/store/general';

const sagaMiddleware = createSagaMiddleware();

const sagas = [
  onRequestAutocomplete,
  onUpdateOptions,
  setReturnDateWatcher,
  onRemoveBikesFromProduct,
  onUpdateLocations,
  onUpdateDates,
  onRetrieveRecentCities,
  onLoadPopularCities,
  onLoadArrivalPopularCities,
  onStoreRecentCity,
  onRemoveRecentCity,
  onSetLocation,
  onSwitchLocations,
  onSetDepartureDate,
  onSetArrivalDate,
  onSetProductQuantity,
  onHideReturnDate,
  onUpdateSearchLocations,
];

let unsubscribe: unknown;

if (typeof unsubscribe === 'function') {
  unsubscribe();
}

const options: StoreGeneralField['options'] = {
  ...getOptions({ initialSearchMaskValues: {} }),
};

const defaultStore = {
  general: {
    options,
    server: false,
    features: {
      ...defaultFeatures,
      ...options.features,
    },
  },
};

export const getStore = (initialState?: any) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState || defaultStore,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: {
          ignoredActions: ['general/setCmsApiInstance', 'ui/toggleOverlay'],
          ignoredPaths: ['general.cmsApiInstance', 'ui.openedOverlay'],
        },
      }).concat(sagaMiddleware),
    devTools: isDevelopment(),
  });

export const store = getStore();

featureToggler(store.dispatch);

// register sagas
sagas.map((saga) => sagaMiddleware.run(saga));

// Add some options to the body when the options change.
if (isIsolated) {
  unsubscribe = store.subscribe(() => {
    const { general } = store.getState();
    const { options } = general;
    const { variant } = options;

    variants.map((v) => `variant-${v.value}`).forEach((v) => document.body.classList.remove(v));

    document.body.classList.add(`variant-${variant}`);
  });
}
