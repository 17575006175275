import { ProductsId, SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { relationProductsSelector, selectedProductsSelector } from 'app/store/selectors';
import { put, select } from 'redux-saga/effects';
import {
  hideReturnDate,
  SET_RELATION_PRODUCTS,
  setDepartureDate,
  setProductQuantity,
  setReturnDate,
  updateSearchLocation,
} from 'app/store/actions';
import { ProductDataType } from 'app/models/types/store/product';
import { uniq } from 'lodash';
import { SearchMaskLocation } from 'app/models/types/store/location';
import { take } from 'redux-saga-test-plan/matchers';
import { isUuid } from 'app/functions/general/isUuid';

export function* updateSearchMaskValues(
  searchMaskValues: SearchMaskMountOptions['initialSearchMaskValues'],
) {
  const departureId = searchMaskValues.departureCity;
  const arrivalId = searchMaskValues.arrivalCity;

  if (departureId) {
    const key = isUuid(departureId) ? 'uuid' : 'legacyId';
    yield put(
      updateSearchLocation({
        direction: 'from',
        location: {
          [key]: departureId,
          stationId: searchMaskValues?.departureStop,
          stationName: searchMaskValues?.departureStopName,
        } as SearchMaskLocation,
        isInit: true,
      }),
    );
    yield take(SET_RELATION_PRODUCTS);
  }

  if (arrivalId) {
    const key = isUuid(arrivalId) ? 'uuid' : 'legacyId';

    yield put(
      updateSearchLocation({
        direction: 'to',
        location: {
          [key]: arrivalId,
          stationId: searchMaskValues?.arrivalStop,
          stationName: searchMaskValues?.arrivalStopName,
        } as SearchMaskLocation,
        isInit: true,
      }),
    );
    yield take(SET_RELATION_PRODUCTS);
  }

  if (searchMaskValues.departureDate) {
    yield put(setDepartureDate(Number(searchMaskValues.departureDate)));
  }
  if (searchMaskValues.returnDate) {
    yield put(setReturnDate(Number(searchMaskValues.returnDate)));
  } else {
    yield put(hideReturnDate());
  }
  if (searchMaskValues.products) {
    const products = Object.keys(searchMaskValues.products) as ProductsId[];
    const selectedProducts: { [key in ProductsId]?: ProductDataType } =
      yield select(selectedProductsSelector);
    const selectedProductsIds: ProductsId[] = uniq([
      ...(Object.keys(selectedProducts) as ProductsId[]),
      ...products,
    ]);
    const relationProducts = yield select(relationProductsSelector);
    const finalProducts = selectedProductsIds.filter((selectedProduct) =>
      Object.keys(relationProducts).includes(selectedProduct),
    );
    // If there's no adult in product, then set adult product quantity to 0 to overide default quantity set by productType in SDK.
    if (!finalProducts.includes('adult')) {
      yield put(setProductQuantity('adult', 0));
    }

    for (let i = 0; i < finalProducts.length; i++) {
      const amount = searchMaskValues.products[finalProducts[i]] || 0;
      if (typeof amount !== 'undefined') {
        yield put(setProductQuantity(finalProducts[i], amount));
      }
    }
  }
}
