import { Country } from '@webc/meeseeks-ui-sdk';
import { AutocompleteResult, PartialSearchMaskLocation } from 'app/models/types/store/location';

export const mapAutocompleteToLocation = (
  result: AutocompleteResult & { stationId?: string; stationName?: string },
): PartialSearchMaskLocation => ({
  uuid: result.id,
  legacyId: result.legacyId,
  name: result.name,
  stationId: result.stationId,
  stationName: result.stations?.find((station) => station.id === result.stationId)?.name,
  countryCode: result.country.toUpperCase() as Country,
});
