import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreTranslationsField } from 'app/models/types/store/translations';

const defaultState: StoreTranslationsField = {
  strings: {},
};

export const translationSlice = createSlice({
  name: 'translation',
  initialState: defaultState,
  reducers: {
    requestTranslationsSucceeded(state, action: PayloadAction<StoreTranslationsField>) {
      state.strings = action.payload.strings;
      state.dates = action.payload.dates;
    },
  },
});

export default translationSlice.reducer;

export const { requestTranslationsSucceeded } = translationSlice.actions;
