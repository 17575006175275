import { TripModePicker as TripModePickerView } from 'app/components/ui/TripModePicker';
import { connect } from 'react-redux';
import { returnDateVisibleSelector } from 'app/store/selectors';
import { hideReturnDate, showReturnDate } from 'app/store/actions';
import { emitFieldChangedEvent } from 'app/events';
import { FIELD_TRIP_MODE } from 'app/storage/fieldNames';
import { StoreState } from 'app/models/types';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { WithOptions } from '../WithOptions';
import { requestSearch } from 'app/store/thunk/general/requestSearchThunk';
import { AppDispatch } from 'app/hooks/appHooks';

const mapStateToProps = (state: StoreState) => ({
  isRoundTrip: returnDateVisibleSelector(state),
});

const mapDispatchToProps =
  (dispatch: AppDispatch, ownProps: { options: SearchMaskMountOptions }) => () => {
    const callRequestSearch = () => dispatch(requestSearch({}));
    return {
      onClickOneWayToggle: () => {
        emitFieldChangedEvent(FIELD_TRIP_MODE, 'oneway');
        dispatch(hideReturnDate());

        if (ownProps.options.autoUpdate) {
          callRequestSearch();
        }
      },
      onClickRoundTripToggle: () => {
        dispatch(showReturnDate());
        emitFieldChangedEvent(FIELD_TRIP_MODE, 'roundtrip');

        if (ownProps.options.autoUpdate) {
          callRequestSearch();
        }
      },
    };
  };

export const TripModePicker = WithOptions(
  connect(mapStateToProps, mapDispatchToProps)(TripModePickerView),
);
