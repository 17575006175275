import { features as defaultFeatures } from 'app/config/features';
import { StoreGeneralField } from 'app/models/types/store/general';
import { defaultOptions } from 'app/options';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const defaultState: StoreGeneralField = {
  options: defaultOptions,
  server: false,
  features: {
    ...defaultFeatures,
  },
};

export const generalSlice = createSlice({
  name: 'general',
  initialState: defaultState,
  reducers: {
    updateOptions(state, action: PayloadAction<StoreGeneralField['options']>) {
      state.options = { ...state.options, ...action.payload };
      state.features = {
        ...state.features,
        ...(action.payload.features && action.payload.features),
      };
    },
    toggleFeature(state, action: PayloadAction<{ name: string; value: boolean }>) {
      state.features = { ...state.features, [action.payload.name]: action.payload.value };
    },
    setCmsApiInstance(state, action: PayloadAction<StoreGeneralField['cmsApiInstance']>) {
      state.cmsApiInstance = action.payload;
    },
  },
});

export default generalSlice.reducer;

export const { updateOptions, toggleFeature, setCmsApiInstance } = generalSlice.actions;
