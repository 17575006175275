import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreUiField } from 'app/models/types/store/ui';

const defaultState: StoreUiField = {
  loading: true,
  focusedPart: null,
  openedOverlay: null,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState: defaultState,
  reducers: {
    loadUI(state) {
      state.loading = false;
    },
    waitUI(state) {
      state.loading = true;
    },
    updateFocusedPart(state, action: PayloadAction<StoreUiField['focusedPart']>) {
      state.focusedPart = action.payload;
    },
    toggleOverlay(state, action: PayloadAction<StoreUiField['openedOverlay']>) {
      // @ts-expect-error test
      state.openedOverlay = !state.openedOverlay ? action.payload : null;
    },
  },
});

export default uiSlice.reducer;

export const { loadUI, waitUI, updateFocusedPart, toggleOverlay } = uiSlice.actions;
