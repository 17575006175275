import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { serverSelector, loadingSelector } from 'app/store/selectors';

type DumbLazyComponentProps = {
  fallback: React.ReactElement;
  children: React.ReactNode;
};

export const LazyComponent: FC<DumbLazyComponentProps> = ({ fallback, children, ...rest }) => {
  const server = useSelector(serverSelector);
  const loading = useSelector(loadingSelector);

  if (server || loading) {
    return fallback;
  }

  return (
    <React.Suspense fallback={fallback} {...rest}>
      {children}
    </React.Suspense>
  );
};
