import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { optionsSelector } from 'app/store/selectors';
import { getIsGlobalTripSearch, SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { shopUrls } from 'app/config/shopUrls';
import { getQueryString, getSearchMaskValuesFromQueryString, redirectToUrl } from 'app/functions';
import { updateSearchMaskValues } from 'app/store/sagas';
import { StoreTranslationsDateField } from 'app/models/types/store/translations';
import { fetchDatesTranslations, fetchTranslations } from 'app/providers';
import { loadUI } from 'app/store/reducers/ui';
import { requestTranslationsSucceeded } from 'app/store/reducers/translation';
import { updateOptions } from 'app/store/reducers/general';

export function* requestTranslations(locale: string) {
  const [translationsResponse, translationsDatesResponse]: [
    { [key: string]: string },
    StoreTranslationsDateField,
  ] = yield all([call(fetchTranslations, locale), call(fetchDatesTranslations, locale)]);

  yield put(
    requestTranslationsSucceeded({
      strings: translationsResponse,
      dates: translationsDatesResponse,
    }),
  );
}
export function* onUpdateSearchOptionsSagaWorker(action: ReturnType<typeof updateOptions>) {
  const oldOptions: ReturnType<typeof optionsSelector> = yield select(optionsSelector);
  const newOptions: SearchMaskMountOptions = { ...oldOptions, ...action.payload };
  const isGlobalTripSearch = getIsGlobalTripSearch({
    locale: newOptions.locale,
    departureCountryCode: newOptions.initialSearchMaskValues.departureCountryCode,
    arrivalCountryCode: newOptions.initialSearchMaskValues.arrivalCountryCode,
  });

  if (isGlobalTripSearch) {
    const host = shopUrls['en'];
    const path = 'search';
    const queryString = getQueryString();
    const urlToRedirectTo = `${window.location.protocol}//${host}/${path}${queryString}&isGlobalTripSearch=1`;
    redirectToUrl(urlToRedirectTo);
    return null;
  }

  const searchMaskValues = {
    ...action.payload?.initialSearchMaskValues,
    ...getSearchMaskValuesFromQueryString(getQueryString()),
  } as SearchMaskMountOptions['initialSearchMaskValues'];

  yield call(updateSearchMaskValues, searchMaskValues);
  yield call(requestTranslations, newOptions.locale);
  yield put(loadUI());
}
export function* onUpdateOptions() {
  yield takeLatest<ReturnType<typeof updateOptions>>(
    updateOptions,
    onUpdateSearchOptionsSagaWorker,
  );
}
