export const DEFAULT_CODE = 'en';

const fetchHash = async (locale: string) => {
  const { hash } = await fetch(`${process.env.TRANSLATIONS_ENDPOINT}/${locale}/hash.json`).then(
    (res) => res.json(),
  );
  return hash;
};

const fetchTranslation = async (locale: string, hash: string) => {
  const trans = await fetch(`${process.env.TRANSLATIONS_ENDPOINT}/${locale}/${hash}.json`).then(
    (res) => res.json(),
  );
  return trans;
};

export const fetchTranslations = async (locale: string) => {
  let hash = '';
  let translation = {};

  try {
    hash = await fetchHash(locale);
  } catch (_) {
    locale = DEFAULT_CODE;
    hash = await fetchHash(locale);
  } finally {
    translation = await fetchTranslation(locale, hash);
  }

  return translation;
};
