import * as styles from './ProductPopupContent.scss';
import { Button } from '@flixbus/honeycomb-react';
import classnames from 'classnames';
import ProductList from 'app/components/product/ProductList';
import React, { useEffect, useRef, useState } from 'react';
import { tabTrap } from 'app/providers/tabTrap';
import { breakpointMatches } from 'app/functions';
import { useTranslations } from 'app/hooks/useTranslations';
import { a11yLabelsKeys } from 'app/providers/a11yLabels';
import {
  currentProductTypesExtendedSelector,
  currentProductTypesSelector,
  featureUsVeteransPromotedSelector,
  maxTotalPassengersCountSelector,
  passengerCountSelector,
} from 'app/store/selectors';
import { useSelector } from 'react-redux';
import { ProductPopupContentType } from 'app/models/types/store/ui';
import { DiscountInfo } from './DiscountInfo';
import { GroupTripMessage } from './GroupTripMessage';

export const ProductPopupContent = ({
  extendedProductsInformationLink,
  wrapperRef,
  toggleInfoMessageSeen,
  areGroupTripsAvailable,
  showExtendedProductsInfo,
  handleSetProductQuantity,
  infoMessageRef,
  handleClose,
  infoMessagesSeen,
}: ProductPopupContentType) => {
  const products = useSelector(currentProductTypesSelector);
  const [isExtendedVisible, setExtendedVisible] = useState(false);
  const passengerCount = useSelector(passengerCountSelector);
  const usVeteransPromoted = useSelector(featureUsVeteransPromotedSelector);
  const extendedProducts = useSelector(currentProductTypesExtendedSelector);
  const maxTotalPassengerCount = useSelector(maxTotalPassengersCountSelector);
  const hasExtendedProducts = Object.keys(extendedProducts).length > 0;
  const extendedOpen = isExtendedVisible && hasExtendedProducts;
  const extendedClosed = !isExtendedVisible && hasExtendedProducts;
  const isSmallBreakpoint = breakpointMatches('small').matches;
  const backButtonWrapper = useRef<HTMLDivElement | null>(null);
  const discountsButtonWrapper = useRef<HTMLDivElement | null>(null);
  const {
    getTranslationDiscountsBack,
    getTranslationBackToProductsAriaLabel,
    getTranslationDiscountsShowAriaLabel,
  } = useTranslations({
    getTranslationDiscountsBack: 'search_bundle.search_widget.passengers.popup.btn.social_hide',
    getTranslationDiscountsShowAriaLabel: a11yLabelsKeys.products.showMoreDiscounts,
    getTranslationBackToProductsAriaLabel: a11yLabelsKeys.products.backToProductSelection,
  });

  const [isRendered, setIsRendered] = useState(false);
  useEffect(() => {
    if (!isRendered) {
      setIsRendered(true);
    }
  }, []);

  useEffect(() => {
    if (isRendered) {
      const buttonRef = isExtendedVisible ? backButtonWrapper : discountsButtonWrapper;
      buttonRef.current?.querySelector('button')?.focus();
    }
  }, [isExtendedVisible]);

  return (
    <div className={styles.productWrapperList}>
      {extendedOpen && (
        <div className={styles.productWrapperExtendedHeader}>
          <span ref={backButtonWrapper}>
            <Button
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                setExtendedVisible(false);
              }}
              extraClasses={classnames(styles.productButton, styles.backButton)}
              aria-label={getTranslationBackToProductsAriaLabel()}
            >
              {getTranslationDiscountsBack()}
            </Button>
          </span>

          <DiscountInfo
            showExtendedProductsInfo={showExtendedProductsInfo}
            handleClose={handleClose}
            extendedProductsInformationLink={extendedProductsInformationLink}
            wrapperRef={wrapperRef}
          />
        </div>
      )}

      <GroupTripMessage
        infoMessageRef={infoMessageRef}
        areGroupTripsAvailable={areGroupTripsAvailable}
        passengerCount={passengerCount}
      />

      <ProductList
        products={isExtendedVisible ? extendedProducts : products}
        onSetProductQuantity={handleSetProductQuantity}
        onProductMessagesSeen={toggleInfoMessageSeen}
        productMessagesSeen={infoMessagesSeen}
        maxTotalPassengerCount={maxTotalPassengerCount}
        passengerCount={passengerCount}
        usVeteransPromoted={usVeteransPromoted}
        tabTrapAction={(e: React.KeyboardEvent) => {
          if (isSmallBreakpoint) {
            return;
          }

          return tabTrap({
            event: e,
            firstItemSelector: '#productSummary',
            lastItemSelector: '#lastPlus',
            isActive: !hasExtendedProducts || (hasExtendedProducts && extendedOpen),
            wrapper: wrapperRef.current,
            callback: handleClose,
            skipCheck: true,
          });
        }}
      />

      {extendedClosed && (
        <div className={styles.productWrapperExtendedFooter}>
          <span ref={discountsButtonWrapper}>
            <Button
              onClick={(e: React.SyntheticEvent) => {
                e.stopPropagation();
                setExtendedVisible(true);
              }}
              extraClasses={styles.productButton}
              aria-label={getTranslationDiscountsShowAriaLabel()}
            >
              {getTranslationDiscountsShowAriaLabel()}
            </Button>
          </span>

          <DiscountInfo
            showExtendedProductsInfo={showExtendedProductsInfo}
            handleClose={handleClose}
            extendedProductsInformationLink={extendedProductsInformationLink}
            wrapperRef={wrapperRef}
            isLast
          />
        </div>
      )}
    </div>
  );
};
