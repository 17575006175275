import { ThunkAction } from '@reduxjs/toolkit';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import {
  arrivalCitySelector,
  departureCitySelector,
  departureDateTimestampSelector,
  featuresSelector,
  localeSelector,
  optionsSelector,
  passengerCountSelector,
  selectedProductsSelector,
  selectedReturnDateSelector,
  selectedRouteNameSelector,
  wheelchairSelectedSelector,
} from '../../selectors';
import { emitSearchForStationEvent, emitRequestSearchEvent } from 'app/events';
import {
  isSearchInvalid,
  getPayloadForSearchResultsRedirect,
  redirectToSearchResultsPage,
  mapLocationToAutocomplete,
} from 'app/functions';
import { CityDetailsResult, PartialSearchMaskLocation } from 'app/models/types/store/location';
import { storeRecentCity } from '../../actions/location';
import { RootState } from 'app/hooks/appHooks';

const DIRECTION_TO = 'to';
const DIRECTION_FROM = 'from';

export type RequestSearchType = { noRedirect?: boolean; isGlobalTripSearch?: boolean };

export type ThunkType<ExtraArguments> = ThunkAction<void, RootState, ExtraArguments, any>;

export const requestSearch = ({
  noRedirect,
  isGlobalTripSearch,
}: RequestSearchType): ThunkType<RequestSearchType> => {
  return (dispatch, getState) => {
    const state = getState();

    const options: SearchMaskMountOptions = optionsSelector(state);
    const features: { [key: string]: boolean } = featuresSelector(state);

    const departureCity: CityDetailsResult | PartialSearchMaskLocation =
      departureCitySelector(state);
    const arrivalCity: CityDetailsResult | PartialSearchMaskLocation = arrivalCitySelector(state);
    const routeName: string = selectedRouteNameSelector(state);
    const departureDate: number = departureDateTimestampSelector(state);
    const returnDate: number | null = selectedReturnDateSelector(state);
    const selectedProducts: Record<string, any> = selectedProductsSelector(state);
    const locale: string = localeSelector(state);
    const passengerCount: number = passengerCountSelector(state);
    const wheelchairsCount: number | undefined = wheelchairSelectedSelector(state);

    if (
      isSearchInvalid({
        passengerCount,
        departureDate,
        wheelchairsCount,
        departureCity: departureCity as CityDetailsResult,
        arrivalCity: arrivalCity as CityDetailsResult,
      })
    ) {
      return;
    }

    dispatch(
      storeRecentCity(
        DIRECTION_FROM,
        mapLocationToAutocomplete(departureCity as CityDetailsResult),
      ),
    );
    dispatch(
      storeRecentCity(DIRECTION_TO, mapLocationToAutocomplete(arrivalCity as CityDetailsResult)),
    );

    const normalizedPayload = getPayloadForSearchResultsRedirect({
      departureCity,
      arrivalCity,
      departureDate,
      returnDate,
      locale,
      routeName,
      selectedProducts,
    });

    if (!!arrivalCity.stationId || !!departureCity.stationId) {
      emitSearchForStationEvent();
    }
    emitRequestSearchEvent(normalizedPayload);
    if (noRedirect) {
      return;
    }

    redirectToSearchResultsPage(normalizedPayload, options, features, isGlobalTripSearch);
  };
};
