import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  departureDateTimestampSelector,
  featuresSelector,
  optionsSelector,
  passengerCountSelector,
  selectedLocationsSelector,
  selectedProductsSelector,
  selectedReturnDateSelector,
  wheelchairSelectedSelector,
} from '../../selectors';

import {
  isSearchInvalid,
  getPayloadForSearchResultsRedirect,
  redirectToSearchResultsPage,
  mapCityDetailResponse,
} from 'app/functions';
import {
  CityDetailsResult,
  SearchMaskDirection,
  SearchMaskLocation,
} from 'app/models/types/store/location';
import { setLocation, updateAutocompleteValue } from '../../actions/location';
import { AppDispatch, RootState } from 'app/hooks/appHooks';
import { fetchCityRelationDetails } from 'app/providers';

export type RedirectToGlobalType = {
  direction: SearchMaskDirection;
  selectedCity: SearchMaskLocation;
};

export const redirectToGlobal = createAsyncThunk<
  void,
  RedirectToGlobalType,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('general/redirectToGlobal', async ({ direction, selectedCity }, { dispatch, getState }) => {
  const state: any = getState();

  const options = optionsSelector(state);
  const selectedLocations = selectedLocationsSelector(state);
  const previouslySelectedLocation = selectedLocations[direction];
  const features = featuresSelector(state);
  const departureDate = departureDateTimestampSelector(state);
  const returnDate = selectedReturnDateSelector(state);
  const selectedProducts = selectedProductsSelector(state);
  const passengerCount = passengerCountSelector(state);
  const wheelchairsCount = wheelchairSelectedSelector(state);

  dispatch(updateAutocompleteValue(direction, selectedLocations[direction]));

  //get city details
  const cityDetailsResponse: Awaited<ReturnType<typeof fetchCityRelationDetails>> =
    await fetchCityRelationDetails(
      options.citiesDetailsEndpoint,
      selectedCity.uuid,
      options.locale,
    );
  if ('code' in cityDetailsResponse) {
    console.error(`Can't retrieve city for location`, selectedCity);
    dispatch(setLocation(direction, previouslySelectedLocation as SearchMaskLocation));
    return;
  }
  const [locationDetails] = cityDetailsResponse.map((c) => mapCityDetailResponse(c));
  const secondLocation = direction === 'from' ? selectedLocations.to : selectedLocations.from;
  const departureCity = direction === 'from' ? locationDetails : secondLocation;
  const arrivalCity = direction === 'to' ? locationDetails : secondLocation;
  const routeName = `${departureCity.name}-${arrivalCity.name}`;

  if (
    isSearchInvalid({
      passengerCount,
      departureDate,
      wheelchairsCount,
      departureCity: departureCity as CityDetailsResult,
      arrivalCity: arrivalCity as CityDetailsResult,
    })
  ) {
    return;
  }

  const normalizedPayload = getPayloadForSearchResultsRedirect({
    departureCity,
    arrivalCity,
    departureDate,
    returnDate,
    locale: options.locale,
    routeName,
    selectedProducts,
  });

  redirectToSearchResultsPage(normalizedPayload, options, features, true);
});
