import { connect } from 'react-redux';
import { optionsSelector } from 'app/store/selectors';
import { StoreState } from 'app/models/types';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { updateOptions } from 'app/store/reducers/general';
import { AppDispatch } from 'app/hooks/appHooks';

const mapStateToProps = (state: StoreState) => ({
  options: optionsSelector(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateOptions: (options: SearchMaskMountOptions) => dispatch(updateOptions(options)),
});

export const WithOptions = connect(mapStateToProps, mapDispatchToProps);
