import { getEventEmitter } from '@flixbus/transclusion-eventing';
import { removeBikesFromProduct, updateDates, updateLocations } from 'app/store/actions';
import { AppDispatch } from 'app/hooks/appHooks';
import { getQueryString, getSearchMaskValuesFromQueryString } from 'app/functions';
import { SearchMaskMountOptions } from '@webc/meeseeks-ui-sdk';
import { updateSearchMaskValues } from 'app/store/sagas';
import { requestSearch } from 'app/store/thunk/general';

// EXTERNAL EVENTS
const EVENT_REMOVE_BIKES = 'search.results.remove_bikes';
const EVENT_OTHER_DATE_SELECTED = 'search.results.other_date_selected';
const EVENT_UPDATE_CITIES = 'search.results.update_cities';
const EVENT_UPDATE_SEARCH_PARAMS_IN_URL = 'search.results.update_search_params_in_url';

export const registerEventListeners = (dispatch: AppDispatch) => {
  const eventEmitter = getEventEmitter();

  eventEmitter.on(EVENT_REMOVE_BIKES, () => {
    dispatch(removeBikesFromProduct());
  });

  eventEmitter.on(EVENT_OTHER_DATE_SELECTED, ({ tripIndex, newDateUTC }) => {
    dispatch(updateDates(tripIndex, newDateUTC));
  });

  eventEmitter.on(EVENT_UPDATE_CITIES, ({ fromCityDetails, toCityDetails }) => {
    dispatch(updateLocations(fromCityDetails, toCityDetails));
  });

  eventEmitter.on(EVENT_UPDATE_SEARCH_PARAMS_IN_URL, () => {
    const searchMaskValues = getSearchMaskValuesFromQueryString(
      getQueryString(),
    ) as SearchMaskMountOptions['initialSearchMaskValues'];

    dispatch(updateSearchMaskValues(searchMaskValues));
    dispatch(requestSearch({ noRedirect: true }));
  });
};
