import React, { FC } from 'react';
import { useTranslations } from 'app/hooks/useTranslations';
import * as styles from './Autocomplete.scss';
import { removeRecentCity } from 'app/store/actions';
import { Icon, IconClose } from 'app/icons';
import { Button } from '@flixbus/honeycomb-react';
import { useAppDispatch } from 'app/hooks/appHooks';

export const RemoveButton: FC<{
  cityId: string;
  cityName: string;
  direction: 'from' | 'to';
}> = ({ cityId, direction, cityName }) => {
  const dispatch = useAppDispatch();
  const { getRemoveLabel } = useTranslations({
    getRemoveLabel: 'search_mask.label.remove',
  });

  return (
    <Button
      aria-label={`${getRemoveLabel()}: ${cityName}`}
      display="square"
      extraClasses={styles.removeButton}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(removeRecentCity(direction, cityId));
        document.getElementById(`searchInput-${direction}`)?.focus();
      }}
      onKeyDown={(e: React.SyntheticEvent) => {
        e.stopPropagation();
      }}
    >
      <Icon appearance="primary" InlineIcon={IconClose} />
    </Button>
  );
};
